.container {
  width: 100vw;
  height: 100vh;
}

.background {
  background-image: linear-gradient(
      to bottom,
      rgba(182, 226, 203, 0.5),
      rgba(153, 214, 183, 0.8)
    ),
    url('/src/assets/background.jpg');
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}
.content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  position: relative;
  height: 100vh;
}

.logo {
  margin-top: 48px;
  width: 26%;
  height: 26%;
}

.title {
  width: 50%;
  text-align: center;
  color: #000000;
  font-weight: bold;
  font-size: 46px;
}

.message {
  text-align: center;
  font-family: companyFont;
  color: #fff4dc;
  font-weight: bold;
  font-size: 70px;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.sub-title {
  width: 50%;
  text-align: center;
  color: #000000;
  font-weight: bold;
  font-size: 30px;
}

.description {
  width: 60%;
  text-align: center;
  color: #000000;
  font-weight: 500;
  font-size: 18px;
}

footer {
  text-align: center;
}

@media (max-width: 600px) {
  .logo {
    margin-top: 24px;
    width: 35%;
    height: 20%;
  }
  .title {
    font-size: 24px;
  }

  .message {
    font-size: 35px;
  }

  .sub-title {
    font-size: 15px;
  }

  .description {
    font-size: 10px;
  }

  footer {
    font-size: 8px;
  }
}

@media (max-width: 900px) and (min-width: 600px) {
  .logo {
    margin-top: 30px;
    width: 30%;
    height: 25%;
  }
  .title {
    font-size: 30px;
  }

  .message {
    font-size: 44px;
  }

  .sub-title {
    font-size: 15px;
  }

  .description {
    font-size: 12px;
  }

  footer {
    font-size: 8px;
  }
}
